*{
    background-color: whitesmoke
}
.overflow-x-scroll {
    position: sticky;
    left: 0;
    width: 100%;
    z-index: 999; /* Adjust the z-index as needed */
  }

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


 .buy {
  position: relative;
  width: 150px;
  height: 50px;
  background: repeating-conic-gradient(from var(--a), blue 0%, blue 5%, transparent 5%,  transparent 40%, blue 50%);
  animation: animate 4s linear infinite;
  border-radius: 15px;

 }

 @property --a {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;

 }

  

  @keyframes animate {
    0% {
      --a: 0deg;
    }
    100% {
      --a: 360deg;
    }
  }

  .buy::before{
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    background: repeating-conic-gradient(from var(--a), red 0%, red 5%, transparent 5%,  transparent 40%, red 50%);
    animation: animate 4s linear infinite;
    animation-delay: -1s;
    border-radius: 10px;
   z-index: 1;
  }
  
  .buy::after {
    content: '>> Buy Now ';
    position: absolute;
    inset: 2px;
    background: #2d2d39;
    border-radius: 15px;
    z-index:2;
    color: whitesmoke;
    font-size: larger;
    text-align: center;
    justify-items: center;
    place-items: center;


  }
  