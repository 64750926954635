@tailwind base;
@tailwind components;
@tailwind utilities;


*{
    font-family: Georgia, serif;
    .login-form {
        transition: transform 0.5s ease;
      }
      
      .login-form:hover {
        transform: rotateY(10deg) rotateX(10deg);
      }

      .scrollbar-hide::-webkit-scrollbar {
        display: none;
      }
      .scrollbar-hide {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
      
      /* animations.css */
      
        @keyframes slideDown {
          from {
            transform: translateY(-10px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
      
      
      





 

      
      
}
